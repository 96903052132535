<template>
    <div
            :class="[
                'sequences-app-wrapper',
                disableAll && 'sequences-app-wrapper--disable-all'
            ]"
    >
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "SequencesAppWrapper",
        computed: {
            disableAll() {
                return this.$store.getters['sequences_store/getActiveStep']?.definition?.mixins?.includes('disable_all_except_anchor')
            }
        }
    }
</script>

<style lang="scss">
    .sequences-app-wrapper {

        &--disable-all {
            &, *:not([data-enable-pointer-events="true"]) {
                pointer-events: none;
            }

            [data-enable-pointer-events="true"], [data-enable-pointer-events="true"] * {
                pointer-events: auto;
            }
        }
    }
</style>